import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import router from './router';
import 'ant-design-vue/dist/reset.css';

const app = createApp(App); // 创建 Vue 应用实例
app.use(ElementPlus);
app.use(router);
app.use(Antd);
app.mount('#app');
// 注册路由
