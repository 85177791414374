// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/HelloWorld',
        name: 'HelloWorld',
        component: () => import('@/components/HelloWorld.vue')
    },

    // Ai平台里的页面
    {
        path: '/',
        name: 'AiPlatform',
        component: () => import('@/components/AIpingtai/AiPlatform.vue'),
        meta:{
            title: '琪物Ai大模型-琪物科技集团旗下产品,琪物集团，琪物科技，琪物科技集团，琪物科技集团有限公司',
            keywords: '琪物Ai大模型,琪物集团,琪物科技,琪物科技集团,琪物科技集团有限公司',
            description: '琪物Ai大模型是一款基于深度学习技术的智能模型训练平台，提供AI模型训练、部署、应用、管理等一站式服务。旗下产品包括琪物Ai定制平台、琪物Ai音乐、琪物AiGC、琪物AiPPT系统、琪物秒推、琪物智能备课、琪物数字人等。'
        }
        
    },
    {
        path: '/AiCustomized',
        name: 'AiCustomized',
        component: () => import('@/components/AIdingzhi/AiCustomized.vue'),
        meta:{
            title: '琪物Ai定制平台-琪物科技集团旗下产品,琪物集团，琪物科技，琪物科技集团，琪物科技集团有限公司，SAAS部署更安全，私有化数据训练，可个性化制定前端',
            keywords:'琪物科技,Ai定制平台,SAAS部署,私有化数据训练,个性化前端设计,数据安全,企业解决方案,智能化定制,技术创新,企业级服务',
            description:'琪物Ai定制平台是琪物科技集团旗下的创新产品，旨在为企业提供高效、安全的人工智能解决方案。该平台支持SAAS部署，确保数据的安全性与私有化训练，帮助企业在个性化需求上实现更大的灵活性。'
        }
    },
    // Ai音乐
    {
        path: '/AIMusic',
        name: 'AIMusic',
        component: () => import('@/components/AIdingzhiSubpage/AIMusic.vue'),
        meta: {
            title: '琪物Ai音乐-琪物科技集团旗下产品,琪物集团，琪物科技，琪物科技集团，琪物科技集团有限公司',
            keywords: '琪物科技,Ai音乐,智能音乐创作,音乐推荐系统,个性化音乐服务,音频分析,数据安全,企业解决方案,技术创新,音乐平台',
            description: '琪物Ai音乐是琪物科技集团旗下的创新产品，专注于为用户提供智能化的音乐创作与推荐服务。通过先进的音频分析技术，平台能够为用户量身定制个性化的音乐体验，帮助企业和个人更好地挖掘音乐的潜力。琪物科技致力于将最新的技术应用于音乐行业，推动音乐创作的智能化与便捷化。'
        }
    },
    // AIGC
    {
        path: '/AIGC',
        name: 'AIGC',
        component: () => import('@/components/AIdingzhiSubpage/AIGC.vue'),
        meta: {
            title: '琪物AiGC-琪物科技集团旗下产品,琪物集团，琪物科技，琪物科技集团，琪物科技集团有限公司',
            keywords: '琪物科技,AiGC,生成内容,智能创作,人工智能,个性化解决方案,技术创新,企业应用,数据安全,智能化服务',
            description: '琪物AiGC是琪物科技集团旗下的前沿产品，旨在为企业和个人提供高效的生成内容解决方案。通过先进的人工智能技术，AiGC能够快速生成个性化的文本、图像及其他多媒体内容，帮助用户提升创作效率与质量。琪物科技致力于推动智能创作的革新，为客户提供安全可靠的技术支持与服务。'
        }
        
    },
    // aiPPPT系统
    {
        path: '/AIPPTxt',
        name: 'AIPPTxt',
        component: () => import('@/components/AIdingzhiSubpage/AIPPTxt.vue'),
        meta: {
            title: '琪物AiPPT系统-琪物科技集团旗下产品,琪物集团，琪物科技，琪物科技集团，琪物科技集团有限公司，AI一键智能生成PPT，输入关键字，AI将为您生成精美的PPT',
            keywords: '琪物科技,AiPPT,智能PPT生成,一键生成,关键字输入,精美设计,企业解决方案,技术创新,演示工具,数据安全',
            description: '琪物AiPPT系统是琪物科技集团旗下的创新产品，旨在为用户提供快速、高效的PPT生成解决方案。用户只需输入关键字，AI技术将自动为您生成精美的PPT，帮助提升工作效率与演示效果。琪物科技致力于结合最新的技术，为企业与个人提供便捷的智能化服务，推动演示文稿创作的智能化转型。'
        }
        
    },
    // AI秒推
    {
        path: '/AIQWmt',
        name: 'AIQWmt',
        component: () => import('@/components/AIdingzhiSubpage/AIQWmt.vue'),
        meta: {
            title: '琪物秒推-琪物科技集团旗下产品,琪物集团，琪物科技，琪物科技集团，琪物科技集团有限公司',
            keywords: '琪物科技,秒推,智能营销,精准推广,广告投放,数据分析,企业解决方案,技术创新,广告优化,营销平台',
            description: '琪物秒推是琪物科技集团旗下的创新产品，专注于智能营销与精准广告推广。通过先进的AI技术与数据分析，平台能够帮助企业优化广告投放，实现高效、精准的营销效果。琪物科技致力于为企业提供智能化的推广工具，提升广告效果与市场竞争力。'
        }
        
    },
    // 智能备课
    {
        path: '/AIbeike',
        name: 'AIbeike',
        component: () => import('@/components/AIdingzhiSubpage/AIbeike.vue'),
        meta: {
            title: '琪物智能备课-琪物科技集团旗下产品,琪物集团，琪物科技，琪物科技集团，琪物科技集团有限公司',
            keywords: '琪物科技,智能备课,教学辅助,AI教学,教育解决方案,个性化备课,技术创新,教师工具,教育平台,课堂优化',
            description: '琪物智能备课是琪物科技集团旗下的创新产品，旨在通过AI技术为教师提供智能化的备课支持。平台能够根据教学需求自动生成教学内容、课件与教学计划，帮助教师提升备课效率与教学质量。琪物科技致力于推动教育领域的智能化进程，为教师提供便捷、精准的教学工具。'
        }
        
    },
    // AI数字人
    {
        path: '/AIszr',
        name: 'AIszr',
        component: () => import('@/components/AIdingzhiSubpage/AIszr.vue'),
        meta: {
            title: '琪物数字人-琪物科技集团旗下产品,琪物集团，琪物科技，琪物科技集团，琪物科技集团有限公司',
            keywords: '琪物科技,数字人,视频制作,虚拟主播,AI技术,智能视频,内容创作,数字化营销,企业宣传,创意视频',
            description: '琪物数字人是琪物科技集团旗下的创新产品，专注于AI驱动的视频制作与视频营销。通过先进的技术，琪物数字人能够快速生成高质量的视频内容，助力企业实现数字化营销与品牌宣传。琪物科技致力于为用户提供便捷的创意视频解决方案，提升内容创作效率与用户体验。'
        }
        
    },
    // 互动大屏
    {
        path: '/InteractiveBig',
        name: 'InteractiveBig',
        component: () => import('@/QiWu/Interactive/InteractiveBig.vue'),
        meta: {
            title: '琪物互动大屏-琪物科技集团旗下产品,琪物集团，琪物科技，琪物科技集团，琪物科技集团有限公司，数字人结合智能交互技术，正在开创智慧与互动体验的新纪元',
            keywords: '琪物科技,互动大屏,数字人,智能交互,智慧城市,用户体验,信息展示,多媒体互动,企业解决方案,创新科技',
            description: '琪物互动大屏是琪物科技集团旗下的一款先进产品，结合数字人和智能交互技术，为用户提供全新的互动体验。该产品致力于在各类场景中实现信息的高效展示与智能交互，开创智慧与互动体验的新纪元。琪物科技致力于推动信息技术的发展，为企业和用户提供创新的解决方案。'
        }
        
    },
    // 数字人
    {
        path: '/Homepage',
        name: 'Homepage',
        component: () => import('@/QiWu/shuziren/Homepage.vue'),
        // 使用路由守卫判断视口宽度切换不同端页面
        beforeEnter: (to, from, next) => {
            if (window.innerWidth < 1200) {
                next('/Homepage-yidong');
            } else {
                next();
            }
        },
        meta: {
            title: '琪物数字人OEM-琪物科技集团旗下产品,琪物集团，琪物科技，琪物科技集团，琪物科技集团有限公司，OEM系统数字人SAAS平台，快速创建属于你的数字人平台 | 可独立部署 | 贴牌',
            keywords: '琪物科技,数字人OEM,SAAS平台,定制化服务,独立部署,贴牌解决方案,虚拟助手,数字人技术,企业应用,创新平台',
            description: '琪物数字人OEM是琪物科技集团旗下的数字人SAAS平台，专为企业提供快速创建属于自己的数字人解决方案。该平台支持独立部署与贴牌，帮助企业实现个性化的虚拟助手与数字人技术应用，提升品牌影响力与客户体验。琪物科技致力于为客户提供灵活、创新的技术平台，助力企业数字化转型。'
        }
        
        
    },
    // 数字人移动
    {
        path: '/Homepage-yidong',
        name: 'Homepage-yidong',
        component: () => import('@/QiWu/shuziren/Homepage-yidong.vue'),
        // 使用路由守卫判断视口宽度切换不同端页面
        beforeEnter: (to, from, next) => {
            if (window.innerWidth > 1200) {
                next('/Homepage');
            } else {
                next();
            }
        },
        meta: {
            title: '琪物数字人OEM-琪物科技集团旗下产品,琪物集团，琪物科技，琪物科技集团，琪物科技集团有限公司，OEM系统数字人SAAS平台，快速创建属于你的数字人平台 | 可独立部署 | 贴牌',
            keywords: '琪物科技,数字人OEM,SAAS平台,定制化服务,独立部署,贴牌解决方案,虚拟助手,数字人技术,企业应用,创新平台',
            description: '琪物数字人OEM是琪物科技集团旗下的数字人SAAS平台，专为企业提供快速创建属于自己的数字人解决方案。该平台支持独立部署与贴牌，帮助企业实现个性化的虚拟助手与数字人技术应用，提升品牌影响力与客户体验。琪物科技致力于为客户提供灵活、创新的技术平台，助力企业数字化转型。'
        }
        
        
    },
    
];


const router = createRouter({
    history: createWebHistory(),
    routes
});
// 全局导航守卫
router.beforeEach((to, from, next) => {
    // 如果目标路由有meta.title信息，则设置文档标题
    if (typeof to.meta.title === 'string') {
      document.title = to.meta.title
    } else {
      document.title = '默认标题' // 设置一个默认标题
    }
    // 设置meta标签内容
    const metaKeywords = document.querySelector('meta[name="keywords"]');
    const metaDescription = document.querySelector('meta[name="description"]');
  
    if (metaKeywords) {
      // 检查 keywords 是否为字符串类型
      const keywords = typeof to.meta.keywords === 'string' ? to.meta.keywords : ''
      metaKeywords.content = keywords // 如果没有keywords，留空
    }
  
    if (metaDescription) {
      // 检查 description 是否为字符串类型
      const description = typeof to.meta.description === 'string' ? to.meta.description : ''
      metaDescription.content = description // 如果没有description，留空
    }
    next()
  })
export default router;
